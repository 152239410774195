.ant-layout {
  /* background: rgb(0,215,255); */
  /* background: radial-gradient(circle, rgba(0,215,255,0.46262254901960786) 3%, rgba(0,168,255,1) 100%); */

}
#demo {
  transform:
    rotate3d(.5,-.866,0,15deg)
    rotate(1deg);
  box-shadow:
    2em 4em 6em -2em rgba(0,0,0,.5),
    1em 2em 3.5em -2.5em rgba(0,0,0,.5);
  transition:
    transform .4s ease,
    box-shadow .4s ease;
  border-radius: .5em;

  &:hover {
    transform:
      rotate3d(0,0,0,0deg)
      rotate(0deg);
  }
  max-width: 400px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  #demo {
    max-width: 323px;
    margin: 0 auto;
  }
}